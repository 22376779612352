import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';

// import assets
import banner from '../images/modular-banner.svg';
import peopleIcon from '../images/people-icon.svg';
import toolsIcon from '../images/tools-icon.svg';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

const IndexWrapper = styled.div``;

const IndexBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${getColor('grey')};

  padding: 20px 0;

  width: 100%;

  img {
    max-width: 100%;
  }

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 100px 0;

    img {
      width: 65%;
    }
  }
`;

const IndexNav = styled.div`
  background-color: ${getColor('complimentaryBlue')};

  padding: 25px 0;

  h3 {
    color: ${getColor('white')};
    font-size: 1.75rem;
    font-weight: ${getFontWeight('black')};
    text-align: center;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    margin: 0;
    padding: 0;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px 0;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }
`;

const NavButton = styled(Link)`
  background-color: ${getColor('accentGreen')};

  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  margin: 15px 100px;
  padding: 0px 20px;

  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 105px;
  width: 350px;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    border: 1px solid ${getColor('white')};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  img {
    max-width: 75px;
  }

  p {
    color: ${getColor('white')};
    font-size: 1.5rem;
    text-align: center;

    margin: 0;
    padding: 0 10px;
  }
`;

const IndexPage = () => (
  <Layout>
    <IndexWrapper id="reach-skip-nav">
      <IndexBanner>
        <img src={banner} alt="OneTrust Modular Loan Process" />
      </IndexBanner>
      <IndexNav>
        <h3>Home Financing For Home Building. Speed. Support. Care.</h3>
        <NavContainer role="navigation">
          <NavButton to="/builder">
            <img src={toolsIcon} alt="Builder or Manufacturer" />
            <p>
              I am a<br />
              <strong>Builder</strong> or <strong>Manufacturer</strong>
            </p>
          </NavButton>
          <NavButton to="/borrower">
            <img src={peopleIcon} alt="Borrower" />
            <p>
              I am a<br />
              <strong>Borrower</strong>
            </p>
          </NavButton>
        </NavContainer>
      </IndexNav>
    </IndexWrapper>
  </Layout>
);

export default IndexPage;
